@import "../../../styles/variables.scss";
$disabled-bg-color: lightgray;

.disabled {
    background-color: $disabled-bg-color;
  }

  
  #protocol-rule-settings table th,
  #protocol-rule-settings table td {
    padding: 0.25rem;
    border-top: 0;
  }