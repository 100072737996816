@import "../../styles/variables";

.dropzone{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    outline: none;
    transition: border .24s ease-in-out;
    width: 100%;
    height: 200px;
    border-style: dashed;
    border-radius: 10px;
    border-color: $primary;
    background-color: #eee;
    margin-bottom: 10px;
}

.dropzone-item{
    text-align: center;
    margin: 3px;
}

.selected-files h3 {
    border-bottom: 2px solid $primary;;
}

.fileIcon {
    width: 20px;
    margin: 5px;
  }

.FileUploadErrorList{
    list-style-type: square;

    li {
        color: $danger;
    }
}
  